import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import { getAirportRedemptionDataSuccess, getAirportRedemptionDataFailure, airportRedemptionDataRequest } from "../slice/redemptionSlice";
const AIRPORT_API_REDEMPTION_URL = "/common/airport/air-award";
const data = {
  mode: "no-cors",
  headers: {
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*"
  },
  loader: true,
  isGenericErr: true
};
function* getAirportRedemptionData() {
  try {
    const response = yield call(api.get, AIRPORT_API_REDEMPTION_URL, data);
    console.log("data", response.data);
    yield put(getAirportRedemptionDataSuccess(response.data));
  } catch (error) {
    yield put(getAirportRedemptionDataFailure(error.message));
  }
}
export function* watchAirportRedemptionData() {
  yield takeLatest(airportRedemptionDataRequest.type, getAirportRedemptionData);
}